var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"organism-availability-drawer"},[_c('OrganismsCommonOrganismDrawer',{ref:"drawer",attrs:{"default-open":_vm.defaultOpen},on:{"drawer:close":function($event){return _vm.$emit('drawer:close')}}},[_c('MoleculesCommonMoleculeHorizontalMenu',{staticClass:"horizontal-menu",attrs:{"sm":"","tab-data":_vm.tabData,"selected-option":_vm.activeTab},on:{"tab-change":_vm.handleTabChange}}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === 'residential'),expression:"activeTab === 'residential'"}]},[_c('div',{staticClass:"top-content"},[_c('MoleculesCommonAvailabilityMoleculeFilters')],1),_vm._v(" "),(Object.keys(_vm.finalStructureSpaces).length)?_c('div',{staticClass:"bottom-content"},_vm._l((_vm.finalStructureSpaces),function(building){return _c('AtomsCommonAvailabilityAtomCollapsibleItem',{key:`building_${building.id}`,ref:`building_${building.id}`,refInFor:true,staticClass:"parent-box",attrs:{"item":building},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title-box"},[_c('p',{staticClass:"title-text"},[_vm._v(_vm._s(building.title))]),_vm._v(" "),_c('span',{staticClass:"circle-sum float-right"},[_vm._v(_vm._s(building.noSpacesResult))])])]},proxy:true},{key:"content",fn:function(){return _vm._l((building.child),function(floor){return _c('AtomsCommonAvailabilityAtomCollapsibleItem',{key:`floor_${floor.id}`,ref:`floor_${floor.id}`,refInFor:true,class:{
                'child-box': true,
                'spaces-leased': floor.fullyLeased
              },scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title-box",staticStyle:{"width":"100%"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","flex":"3"}},[_c('span',{staticClass:"title title-child"},[_vm._v(_vm._s(floor.title))]),_vm._v(" "),_c('span',{staticClass:"subtitle"},[_vm._v(_vm._s(floor.subtitle))])]),_vm._v(" "),_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"flex-end","flex":"1"}},[_c('span',{staticClass:"fully-leased"},[_vm._v(_vm._s(floor.fullyLeased))]),_vm._v(" "),_c('span',{staticClass:"circle-sum"},[_vm._v(_vm._s(floor.noSpacesResult))])])])]},proxy:true},{key:"content",fn:function(){return _vm._l((floor.child),function([entryKey, fspaces]){return _c('div',{key:`${floor.id}-${entryKey}`},[_c('span',{staticClass:"category-title"},[_vm._v(_vm._s(_vm.spaceTypes
                        .find((s) => s.id === Number(entryKey))
                        .space_type_translations.find((s) => s.lang === _vm.locale).name)+"\n                  ")]),_vm._v(" "),_vm._l((fspaces),function(space){return _c('AtomsCommonAvailabilityAtomCollapsibleItem',{key:`subchild${space.id}`,ref:`${building.buildingDetails.code}_${space.spaceDetails.space_code}`,refInFor:true,class:{
                      'subchild-box': true,
                      'space-sold': space.isSold
                    },on:{"toggle":function($event){return _vm.viewOrHideSpace(
                        $event,
                        space.spaceDetails.space_code,
                        building.buildingDetails.code,
                        null,
                        space.spaceDetails
                      )}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title-box"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(space.title))]),_vm._v(" "),_c('span',{staticClass:"surface"},[_c('AtomsCommonAtomIcon',{attrs:{"color":"#000","size":14,"icon":"ruler-vertical"}}),_vm._v("\n                          "+_vm._s(space.useful_sqm)+" "+_vm._s(_vm.$t('sqm'))+"\n                        ")],1),_vm._v(" "),_c('span',{staticClass:"cardinal-point"},[_c('AtomsCommonAtomIcon',{attrs:{"color":"#000","size":14,"icon":"compass"}}),_vm._v("\n                          "+_vm._s(_vm.$t(`${space.cardinalPoint}`))+"\n                        ")],1),_vm._v(" "),(space.isSold)?_c('span',[_vm._v(" - "+_vm._s(space.isSold))]):_vm._e()])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"content-box"},[_c('div',{staticClass:"space-box"},[(space.content.imgUrl)?_c('div',{staticClass:"space-image-box"},[_c('img',{attrs:{"src":space.content.imgUrl,"alt":"space-plan"}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"space-content-box"},[_c('span',{staticClass:"space-description"},[_vm._v(_vm._s(space.content.description))]),_vm._v(" "),_c('span',{staticClass:"space-price"},[_c('strong',[_vm._v(_vm._s(space.content.price.toLocaleString('de-DE'))+" +"+_vm._s(_vm.$t('VAT')))])])])]),_vm._v(" "),(space.spaceDetails.available && _vm.shortlistState)?_c('div',{staticClass:"content-buttons"},[(_vm.activeSpace !== space.id && !_vm.disableExploreSpacePage)?_c('AtomsCommonAtomButton',{staticClass:"explore-btn",attrs:{"icon":"vr","label":`${_vm.$t('Explore in 3D')}`,"custom-label":`${_vm.$t('Explore')}`,"on-click":() => _vm.exploreSpace(space.id),"activate-hover-event":""}}):_vm._e(),_vm._v(" "),(
                              !_vm.requestOfferSpaces.find((sl) => sl.id === space.id) &&
                              !_vm.disableRequestOffer
                            )?_c('AtomsCommonAtomButton',{staticClass:"shortlist-btn",attrs:{"icon":"shortlist-icon","label":`${_vm.$t('Shortlist Space')}`,"custom-label":`${_vm.$t('Add')}`,"on-click":() =>
                                _vm.addToShortlist(
                                  space.spaceDetails,
                                  floor.floorDetails,
                                  building.buildingDetails
                                ),"activate-hover-event":""}}):_vm._e(),_vm._v(" "),(
                              _vm.requestOfferSpaces.find((sl) => sl.id === space.id) &&
                              !_vm.disableRequestOffer
                            )?_c('AtomsCommonAtomButton',{staticClass:"remove-shortlist-btn",attrs:{"icon":"remove-shortlist","label":`${_vm.$t('Remove Space')}`,"custom-label":`${_vm.$t('Remove')}`,"on-click":() =>
                                _vm.removeFromShortlist(
                                  space.spaceDetails,
                                  floor.floorDetails,
                                  building.buildingDetails
                                ),"activate-hover-event":""}}):_vm._e()],1):_vm._e(),_vm._v(" "),(space.spaceDetails.available && !_vm.shortlistState)?_c('div',{staticClass:"content-buttons"},[(_vm.activeSpace !== space.id && !_vm.disableExploreSpacePage)?_c('AtomsCommonAtomButton',{staticClass:"explore-btn",attrs:{"icon":"vr","label":`${_vm.$t('Explore in 3D')}`,"custom-label":`${_vm.$t('Explore')}`,"on-click":() => _vm.exploreSpace(space.id),"activate-hover-event":""}}):_vm._e(),_vm._v(" "),(!_vm.disableRequestOffer)?_c('AtomsCommonAtomButton',{staticClass:"request-offer-btn button-green",attrs:{"icon":"plane-paper","label":`${_vm.$t('Request Offer')}`,"custom-label":`${_vm.$t('Request')}`,"on-click":() =>
                                _vm.openRequestOfferModal(
                                  space.spaceDetails,
                                  building.buildingDetails
                                ),"activate-hover-event":""}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('MoleculesProjectMoleculeContractDetailsContainer',{attrs:{"space-data":space.spaceDetails}})],1)]},proxy:true}],null,true)})})],2)})},proxy:true}],null,true)})})},proxy:true}],null,true)})}),1):_c('div',{staticClass:"bottom-content"},[_c('span',{staticClass:"no-result"},[_vm._v(_vm._s(_vm.$t('No result found')))])])]),_vm._v(" "),(_vm.activeTab === 'project-info')?_c('div',{staticClass:"project-info"},[_c('MoleculesProjectMoleculePopupResult',{attrs:{"project":_vm.currentProject,"default-open":true}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }